export const environment: any = {
  "production": true,
  "API": "https://api.rushmypassport.com/api/",
  "client_url": "https://my.govworks.com",
  "visas": false,
  "header" : {
    "logo": "assets/svg/rmp_dark.svg"
  },
  "support": {
    "phone": "8775039838",    
    "email": "info@rushmypassport.com"
  },
  "login": {
    "enabled": false,
    "logo": "assets/svg/rmp_dark.svg"
  },
  "fedex_logo": true,
  "legal_links": {
    "terms": "https://www.rushmypassport.com/terms-and-conditions/",
    "privacy": "https://www.rushmypassport.com/privacy-policy/",
    "refund": "https://www.rushmypassport.com/refund-policy/"
  },
  "source": {
    "domain": "rmp",
    "main_website": "https://www.rushmypassport.com/",
    "promo_code": true,
    "name": "RushMyPassport"
  },
  "footer": {
    "logo": "assets/svg/rmp_dark.svg",
    "logo_link": "https://www.rushmypassport.com/",
    "copyright": "RushMyPassport.com,LLC"
  },
  "create_lead": true, 
  "seals": {
    "mcafee": "https://www.scanalert.com/RatingVerify?ref=www.rushmypassport.com",
    "truste": "170aae01-cd7c-4ad6-9007-5894464c0962"
  },
  "summary_cta_icon": false,
  "landing_pages":  {
    "root_type": "passport",
    "passport": {
      "enabled": false
    },
    "visa": {
      "enabled": false
    }
  }, 
  "tags": {
    "ga4": ["G-5S9MVF86SJ"],
    "adwords": "AW-1058663885",
    "gtag_conv": "p8lpCM-VPhDN2-f4Aw",
    "mouseflow": "13c2f1e3-c68d-44f1-ac58-2db714afd5df",
    "tag_manager": "GTM-TLRQZR8",
    "bing": "5215337",
    "criteo": 67936,
    "steelhouse": 32094,
    "trustpilot": "4f22c5a500006400051282dd"
  },
  "livechat": {
    "enabled": true,
    "license_id": "1073051"
  },
  "locations_page": {
    "enabled": false
  }
};